import './App.scss';
import React from 'react';
import { Home } from '../pages/home';

function App() {
  return (
    <Home></Home>
  );
}

export default App;
