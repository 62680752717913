import React from "react";
import './home.scss';
import PhoneCircle from '../../assets/icons/phone-circle.svg';
import LinkedinCircle from '../../assets/icons/linkedin-circle.svg';
import MailCircle from '../../assets/icons/mail-circle.svg';
import GithubCircle from '../../assets/icons/github-circle.svg';


import { GoTop } from "../../components/GoTop";
import { motion } from 'framer-motion'

import ReactIcon from '../../assets/icons/react.svg';
import WordpressIcon from '../../assets/icons/wordpress.svg';
import EyeIcon from '../../assets/icons/eye.svg';
import GithubIcon from '../../assets/icons/github.svg';
import PythonIcon from  '../../assets/icons/python.svg';


function Home() {

    return (
        <React.Fragment>
            <GoTop/>
            <header className="d-block d-md-flex align-items-sm-center">
                <h1>Germán Medina</h1>
                <nav className="d-flex justify-content-center">
                    <ul className="d-flex justify-content-around">
                        <motion.li 
                        onClick={() => window.location.replace("/#about")}  
                        whileHover={{scale: 1.3}} 
                        transition={{duration: 0.5} }>
                            about
                        </motion.li>
                        <motion.li 
                        onClick={() => window.location.replace("/#work")} 
                        whileHover={{scale: 1.3}} 
                        transition={{duration: 0.5}}>
                            work
                        </motion.li>
                        <motion.li 
                        onClick={() => window.location.replace("/#contact")} 
                        whileHover={{scale: 1.3}} 
                        transition={{duration: 0.5}}>
                            contact
                        </motion.li>
                    </ul>
                </nav>
            </header>
            <main>
                <section id="about">
                    <div className="presentation d-flex flex-column align-items-center">
                        <p className="draggg">drag me <br/> v</p>
                        <motion.img 
                        drag
                        dragConstraints={{
                            top:-100,
                            bottom: 100,
                            left: -100,
                            right: 100
                        }}

                        dragElastic={0.5}
                        
                        alt="Germán Medina" src={require('../../assets/images/team-lupikenn-ger.jpg')} />
                        <motion.h2>
                            <motion.span
                                whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}
                            >F</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}
                            >u</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>l</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>l</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>s</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>t</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>a</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>c</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>k</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}> </motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>D</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>e</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>v</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>e</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>l</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>o</motion.span>
                            <motion.span
                                whileHover={{ opacity: 0,  }}
                                whileTap={{ scale: 1.2}}>p</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>e</motion.span>
                            <motion.span
                            whileHover={{ opacity: 0 }}
                                whileTap={{ scale: 1.2}}>r</motion.span>
                        </motion.h2>
                        <p className="pres">¡Hola! Mi nombre es Germán, <br />soy un Desarrollador Fullstack, <br />
                        apasionado por aprender <br /> nuevas tecnologías. </p>

                        <h3 onClick={() => window.location.replace("/#contact")}>contactame</h3>
                    </div>
                    <div className="skills">
                        <div className="box-container">
                            <motion.div 
                            initial={{scale:1}}
                            whileHover={{scale:1.2}}
                            className="box d-flex justify-content-center">
                                <h3>ALGUNAS DE LAS TECNOLOGÍAS <br />
                                    EN LAS QUE ESTOY TRABAJANDO</h3>
                            </motion.div>
                        </div>
                        <div className="skills-list d-flex">
                            <div className="col1 d-flex justify-content-end">
                                <motion.h2
                                    initial={{ opacity: 0, position: 'relative', left: '-100%' }}
                                    whileInView= {{ opacity: 1, position: 'relative', left: 0}}
                                    
                                    transition= {{ duration: 1.5}}
                                >skills</motion.h2>
                            </div>
                            <div className="col2 d-flex flex-column justify-content-center">
                                <ul>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}
                                        transition= {{ duration: 0.8}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< C#'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}
                                        transition= {{ duration: 0.7}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< Python'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}                                        
                                        transition= {{ duration: 0.6}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< SQL'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}                                        
                                        transition= {{ duration: 0.5}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< MongoDB'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}                                        
                                        transition= {{ duration: 0.4}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{ scale: 1.1 }}
                                    >
                                        {'< Angular'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}                                        
                                        transition= {{ duration: 0.3}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< React'}
                                    </motion.li>
                                    <motion.li
                                        initial={{ opacity: 0, position: 'relative', right: '-50%' }}
                                        whileInView= {{ opacity: 1, position: 'relative', right: 0}}                                        
                                        transition= {{ duration: 0.2}}
                                        drag
                                        dragConstraints={{ top: 0, bottom: 0, left: 0, right: 0}}
                                        whileHover={{scale: 1.1}}
                                    >
                                        {'< Wordpress'}
                                    </motion.li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="work">
                    <motion.div className="title" >
                        <motion.h2 
                        initial={{scale: 0}} 
                        whileInView={{scale:1}} 
                        drag={"x"}
                        dragConstraints={{left: 10, right: -10}}
                        transition={{duration: 2}}>
                            work
                        </motion.h2>
                    </motion.div>
                    <div className="row work-content">
                         <div className="col-12 row justify-content-between justify-content-sm-center">                      
                            <div className="img-content">
                                <div>Web Lupikenn - Design Studio</div>
                                <img alt="trab-1" src={require('../../assets/images/lupikenn.jpg')}/>
                                <div className="d-flex tech-content-desk">
                                    <div>
                                    <img alt="wordpress" src={ WordpressIcon } />
                                    </div>
                                    <div>
                                    <a href="https://lupikenn.com" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                    </div>
                                </div>
                            </div>
                            <div className="tech-content row" >
                                <div className="tech col-3">
                                    <img alt="wordpress" src={ WordpressIcon } />
                                </div>
                                <div className="tech col-3" >
                                <a href="https://lupikenn.com" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                </div>
                            </div>
                         </div>
                         <div className="col-12 row justify-content-between justify-content-sm-center">
                            <div className="img-content">
                                <div>Web Fipe - Psychology Org.</div>
                                <img alt="trab-1" src={require('../../assets/images/fipe.jpg')}/>
                                <div className="d-flex tech-content-desk">
                                    <div>
                                    <img alt="wordpress" src={ WordpressIcon } />
                                    </div>
                                    <div>
                                    <a href="https://fipe-ifep.org" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                    </div>
                                </div>
                            </div>
                            <div className="tech-content row" >
                                <div className="tech col-3">
                                    <img alt="wordpress" src={ WordpressIcon } />
                                </div>
                                <div className="tech col-3" >
                                <a href="https://fipe-ifep.org" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                </div>
                            </div>
                         </div>
                         <div className="col-12 row justify-content-between justify-content-sm-center">
                            <div className="img-content">
                                <div>Web Garuviando - Personal Blog</div>
                                <img alt="trab-1" src={require('../../assets/images/garuviando.jpg')}/>
                                <div className="d-flex tech-content-desk">
                                    <div>
                                    <img alt="wordpress" src={ WordpressIcon } />
                                    </div>
                                    <div>
                                    <a href="https://garuviando.com" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                    </div>
                                </div>
                            </div>
                            <div className="tech-content row" >
                                <div className="tech col-3">
                                    <img alt="wordpress" src={ WordpressIcon } />
                                </div>
                                <div className="tech col-3" >
                                <a href="https://garuviando.com" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                </div>
                            </div>
                         </div>
                         <div className="col-12 row justify-content-between justify-content-sm-center">
                            <div className="img-content">
                                <div>ToDo Machine - Platzi Practice</div>
                                <img alt="trab-1" src={require('../../assets/images/todo.jpg')}/>
                                <div className="d-flex tech-content-desk">
                                    <div>
                                    <img alt="react" src={ ReactIcon } />
                                    </div>
                                    <div>
                                    <a href="https://github.com/GerMedina91/react-todo" target="_blank" rel="noreferrer" >
                                    <img alt="github" style={{cursor: 'pointer'}} src={ GithubIcon } />
                                    </a>
                                    </div>
                                    <div>
                                    <a href="https://germedina91.github.io/react-todo" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                    </div>
                                </div>
                            </div>
                            <div className="tech-content row" >
                                <div className="tech col-3">
                                    <img alt="react" src={ ReactIcon } />
                                </div>
                                <div className="tech col-3" >
                                <a href="https://github.com/GerMedina91/react-todo" target="_blank" rel="noreferrer" >
                                    <img alt="github" style={{cursor: 'pointer'}} src={ GithubIcon } />
                                    </a>
                                </div>
                                <div className="tech col-3" >
                                <a href="https://germedina91.github.io/react-todo" target="_blank" rel="noreferrer" >
                                    <img alt="web" style={{cursor: 'pointer'}} src={ EyeIcon } />
                                    </a>
                                </div>
                            </div>
                         </div>
                         <div className="col-12 row justify-content-between justify-content-sm-center">
                            <div className="img-content">
                                <div>Hangan Game - Platzi Practice</div>
                                <img alt="trab-1" src={require('../../assets/images/hangan.jpg')}/>
                                <div className="d-flex tech-content-desk">
                                    <div>
                                    <img alt="python" src={ PythonIcon } />
                                    </div>
                                    <div>
                                    <a href="https://github.com/GerMedina91/hangman-game" target="_blank" rel="noreferrer" >
                                    <img alt="github" style={{cursor: 'pointer'}} src={ GithubIcon } />
                                    </a>
                                    </div>
                                </div>
                            </div>
                            <div className="tech-content row" >
                                <div className="tech col-3">
                                    <img alt="react" src={ PythonIcon } />
                                </div>
                                <div className="tech col-3" >
                                <a href="https://github.com/GerMedina91/hangman-game" target="_blank" rel="noreferrer" >
                                    <img alt="github" style={{cursor: 'pointer'}} src={ GithubIcon } />
                                    </a>
                                </div>
                            </div>
                         </div>
                    </div>
                </section>
                <section id="contact">
                        
                    <motion.h2
                        initial={{ opacity: 0, position: 'relative', left: '-100%' }}
                        whileInView= {{ opacity: 1, position: 'relative', left: 0}}
                        transition={{duration: 1}}
                    >random <br />
                    facts</motion.h2>
                   
                    <motion.div 
                    initial={{scale: 1.3, opacity: 0}} whileInView={{scale: 1, opacity: 1}} transition={{duration:1.3}} className="box">
                        <h3>
                            Me gusta mirar anime, <br/>
                            leer mangas, tocar el piano <br/>
                            y jugar a Dungeon&Dragons
                        </h3>
                    </motion.div>
                    <motion.h2
                    initial={{scale:0}} 
                    whileInView={{scale:1}}
                    whileHover={{scale:1.2}} 
                    transition={{duration:1.4}}
                    >
                    contactame</motion.h2>
                    <ul>
                        <motion.li
                        whileHover={{scale: 1.1}}
                        >
                        <a href="https://walink.co/ca71d8" target="_blank" rel="noreferrer" >
                            <img alt="phone" src={PhoneCircle} />
                            
                            <span>/</span>
                            <p>+541164113208</p>
                            </a>
                        </motion.li>
                        <motion.li
                        whileHover={{scale: 1.1}}>
                        <a href="https://www.linkedin.com/in/germedina91/" target="_blank" rel="noreferrer" >
                            <img alt="linkedin" src={LinkedinCircle} />
                            
                            <span>/</span>
                            <p>germedina91</p>
                            </a>
                        </motion.li>
                        <motion.li 
                        whileHover={{scale: 1.1}}>
                        <a href="https://github.com/GerMedina91" target="_blank" rel="noreferrer" >
                            <img  alt="github" src={GithubCircle} />
                            
                            <span>/</span>
                            <p>germedina91</p>
                            </a>
                        </motion.li>
                        <motion.li
                        whileHover={{scale: 1.1}}>
                        <a href="mailto: m.ger.1991@gmail.com" target="_blank" rel="noreferrer" >
                            <img alt="mail" src={MailCircle} />
                        
                            <span>/</span>
                            <p>germedina.work@gmail.com</p>
                        </a>
                        </motion.li>
                    </ul>
                </section>
            </main>
            <footer>
                <ul>
                    <li>
                    <a href="https://walink.co/ca71d8" target="_blank" rel="noreferrer" ><img alt="phone" src={PhoneCircle} /></a>
                    </li>
                    <li>
                    <a href="https://www.linkedin.com/in/germedina91/" target="_blank" rel="noreferrer" ><img alt="linkedin" src={LinkedinCircle} /></a>
                    </li>
                    <li >
                        <a href="https://github.com/GerMedina91" target="_blank" rel="noreferrer" ><img alt="github"  src={GithubCircle} /></a>
                    </li>
                    <li>
                    <a href="mailto: m.ger.1991@gmail.com" target="_blank" rel="noreferrer" ><img alt="mail" src={MailCircle} /></a>
                    </li>
                </ul>
                <div>
                    <a download href={require('../../assets/files/cv-german-medina.pdf')}>Download CV</a>
                </div>
            </footer>
        </React.Fragment>
    );
}

export { Home };